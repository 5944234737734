import { trimSp } from "@components/config/helpers";
import useWindowSize from "@components/_hooks/useWindowSize";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import { useSelector } from "react-redux";


const fontSize = {
    fontSize: '14px'
}

const FooterContactUs = ({type}) => {
    const global_settings = useSelector(state => state.globalReducer.global_settings);
    const {isMobile} = useWindowSize();

    const {
        ad_sales_telephone,
        sales_telephone,
        sales_fax,
        sales_email,
        sales_address,
    } = global_settings;

    return (
        <>
            {isMobile && <Typography className="f-size_3 mb-3 pl-3 mt-2 uppercase font-regular" component="h5">Contact Us</Typography>}
            <ul className={`font-light ${isMobile} ? 'mt-5 pl-3 pr-3' : ''`} style={fontSize}>
                <li>
                    <Link  href={`tel:${trimSp(sales_telephone)}`}>{sales_telephone}</Link>
                </li>
                <li>{sales_email}</li>
                {/* sales fax */}
                <li dangerouslySetInnerHTML={{
                            __html: sales_address,
                    }}/>
            </ul>
        </>
    ) 
};

export default FooterContactUs;
